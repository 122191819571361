<template>
  <div
    class="p-field-checkbox"
    :class="[
      {
        'p-highlight': checked,
        'p-disabled': $attrs.disabled,
        'p-checkbox-sm': size === 'small',
      },
      wrapperClass,
    ]"
    :style="wrapperStyle"
    @click="onClick($event)"
  >
    <div :class="containerClass" :style="style" @click.stop.prevent.self="onClick($event)">
      <div class="p-hidden-accessible">
        <input
          ref="input"
          type="checkbox"
          :checked="checked"
          :value="value"
          v-bind="$attrs"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
      <div
        ref="box"
        :class="[
          'p-checkbox-box',
          {
            'p-highlight': checked,
            'p-focus': focused,
          },
        ]"
        role="checkbox"
        :aria-checked="checked"
        @click="onClick($event)"
      >
        <span class="p-checkbox-icon pi pi-check"></span>
      </div>
    </div>
    <label :for="$attrs.id" class="p-ml-2">
      <slot />
    </label>
  </div>
</template>

<script>
import { ObjectUtils } from "primevue/utils";

export default {
  inheritAttrs: false,
  emits: ["click", "update:modelValue", "change"],
  props: {
    value: null,
    modelValue: null,
    binary: Boolean,
    class: null,
    style: null,
    wrapperClass: null,
    wrapperStyle: null,
    size: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    onClick(event) {
      if (!this.$attrs.disabled) {
        let newModelValue;

        if (this.binary) {
          newModelValue = !this.modelValue;
        } else {
          if (this.checked)
            newModelValue = this.modelValue.filter(val => !ObjectUtils.equals(val, this.value));
          else newModelValue = this.modelValue ? [...this.modelValue, this.value] : [this.value];
        }

        this.$emit("click", event);
        this.$emit("update:modelValue", newModelValue);
        this.$emit("change", event, newModelValue);
        this.$refs.input.focus();
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
  computed: {
    checked() {
      return this.binary ? this.modelValue : ObjectUtils.contains(this.value, this.modelValue);
    },
    containerClass() {
      return [
        "p-checkbox p-component",
        this.class,
        {
          "p-checkbox-checked": this.checked,
          "p-checkbox-disabled": this.$attrs.disabled,
          "p-checkbox-focused": this.focused,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.p-field-checkbox:not(.p-disabled) {
  &,
  label {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    border-color: var(--primary-color);
  }
}
</style>
