<template>
  <header>
    <Menu id="nav_menu" :model="items" :class="{ collapsed: collapsed }">
      <template #start>
        <div
          class="p-mx-0 p-mb-2 p-mt-0 p-d-flex p-text-center p-flex-column p-jc-center p-text-env"
          v-if="checkEnv"
        >
          <span class="p-text-uppercase">
            {{ checkEnv }}
          </span>
          <span v-if="!collapsed && checkEnv !== 'DEV'">
            {{ currentVersion }}
          </span>
        </div>
        <div class="mainWrapper p-d-flex p-jc-between p-width-100">
          <div class="logo">
            <router-link to="/">
              <Logo />
            </router-link>
          </div>
          <Button
            icon="pi"
            text
            rounded
            aria-label="Collapse"
            @click="toggleCollapse"
            class="toggle-button"
            :title="collapsed ? 'Expand' : 'Collapse'"
          >
            <icon data="@icon/arrow-left.svg" :fill="false" width="1.5em" height="1.5em" />
          </Button>
        </div>
      </template>
      <template #item="{ item }">
        <router-link
          :to="item.to"
          v-slot="{ navigate, href, route, isActive }"
          custom
          v-if="!item.items && item.to"
        >
          <a
            :href="href"
            @click="navigate"
            class="p-menuitem-link p-d-flex"
            :class="{ 'router-link-active': isActive || isSubPageActive(route) }"
            aria-hidden="true"
            tabindex="-1"
            v-tooltip="{
              value: collapsed ? item.label : null,
              class: 'animated-navigation-tooltip',
            }"
          >
            <icon :data="item.icon" :fill="false" class="p-menuitem-icon" />
            <span class="p-menuitem-text">{{ item.label }}</span>
            <Tag
              v-if="item.isNewFeature"
              value="New Offering"
              class="p-tag-pink p-tag-outlined p-ml-auto"
            />
          </a>
        </router-link>
        <a
          class="p-menuitem-link"
          aria-hidden="true"
          tabindex="-1"
          v-else-if="!item.items"
          :title="item.label"
        >
          <icon :data="item.icon" :fill="false" class="p-menuitem-icon" />
          <span class="p-menuitem-text">{{ item.label }}</span>
          <Tag
            v-if="item.isNewFeature"
            value="New Offering"
            class="p-tag-pink p-tag-outlined p-ml-auto"
          />
        </a>
        <template v-else>{{ item.label }}</template>
      </template>
      <template #end v-if="userLogged">
        <FeedbackButton :collapsed="collapsed" />
        <ProfileMenu :user="user" :collapsed="collapsed" />
      </template>
    </Menu>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Menu from "primevue/menu";
import Button from "primevue/button";
import Tag from "primevue/tag";
import Tooltip from "primevue/tooltip";
import ProfileMenu from "@/components/elements/ProfileMenu";
import Logo from "@/components/svg/Logo";
import { useAccountStore } from "@/stores/account";
import type { User } from "@/stores/account";
import { useScreen, useGrid } from "vue-screen";
import FeedbackButton from "./contact-us/FeedbackButton";
import buildVersion from "../version.json";

export default defineComponent({
  name: "Header",
  components: {
    Logo,
    Menu,
    Button,
    Tag,
    ProfileMenu,
    FeedbackButton,
  },
  directives: {
    tooltip: Tooltip,
  },
  setup: () => {
    return {
      accountStore: useAccountStore(),
      screen: useScreen(),
      grid: useGrid(),
    };
  },
  props: {
    user: Object as User,
    items: Array,
  },
  data() {
    return {
      collapsed: false,
      creatingSrf: false,
      currentVersion: buildVersion,
    };
  },
  mounted() {
    this.collapsed = !this.grid["2xl"];
  },
  computed: {
    userLogged() {
      return this.accountStore.userLogged;
    },
    hasSrfAccess() {
      return this.user?.srfAccess && !this.user?.isUserPayingAgent && !this.user?.isUserIssuer;
    },
    hasGenTwoProAccess() {
      return this.user?.hasGenTwoProAccess;
    },
    isStaging() {
      return import.meta.env.VITE_APP_ENVIRONMENT === "staging";
    },
    isProduction() {
      return import.meta.env.VITE_APP_ENVIRONMENT === "production";
    },
    checkEnv() {
      const currentHostname = window.location.hostname;

      if (this.isProduction) {
        return false;
      }
      if (this.isStaging) {
        const regex = /app\.(testing2?)\.acpt\.gentwo\.com/;
        const match = currentHostname.match(regex);
        return match ? match[1] : "Acpt";
      }
      return "DEV";
    },
  },
  watch: {
    screen: {
      handler() {
        this.collapsed = !this.grid["2xl"];
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      // split path into components
      // parent path will always be in array pos 1 if path starts with a "/"
      const parentPath =
        "/" +
        (this.$route.path.startsWith("/")
          ? this.$route.path.split("/")[1]
          : this.$route.path.split("/")[0]);

      if (this.$router.options.history.state.back) {
        this.$router.back();
      } else if (parentPath !== this.$route.path) {
        this.$router.replace({ path: parentPath });
      } else {
        this.$router.back();
      }
    },
    isSubPageActive(route) {
      const isNewItem = route?.path.includes("new");
      const isNewRoute = this.$route.path.includes("new") || this.$route.path.includes("create");

      return (
        route &&
        ((this.$route.path.startsWith(route.path) && !isNewRoute && !isNewItem) ||
          (this.$route.path.includes(`${route.path.split("/")[1]}/`) && isNewItem && isNewRoute))
      );
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
});
</script>

<style scoped lang="scss">
header {
  display: flex;
  height: 100%;
  position: sticky;
  top: 0;
  max-height: 100vh;

  :deep() {
    #nav_menu {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      min-width: 320px;
      width: 320px;
      background: var(--nav-menu-bg-color);
      padding: 0 0 $gutter 0;
      border-width: 0 1px 0 0;
      border-style: solid;
      border-color: var(--border-color);
      font-size: 1.1666666em;
      transition: all 0.2s ease-in-out;
      overflow-y: auto;

      @media (max-width: 1024px) and (min-width: 768px) {
        min-width: 220px;
        width: 220px;
      }

      .p-text-env {
        color: white;
        font-size: 0.9rem;
        background-color: var(--secondary-danger-button);
        font-weight: 700;
        padding: 5px;
        text-transform: uppercase;
      }

      &.collapsed {
        min-width: 60px;
        width: auto;

        .logo,
        .p-menuitem-text,
        .p-tag {
          display: none !important;
        }
        .p-menu-list {
          width: 60px;
          padding-right: 0;
        }
        .p-submenu-header {
          display: none;
        }
        .toggle-button {
          transform: rotate(180deg);
        }
        .p-menuitem-content {
          display: inline-block;
          &:hover {
            a {
              background: var(--nav-menu-active-bg-color);
              .svg-icon {
                transition: transform ease 250ms;
                transform: translate(-5px, 0);
              }
            }
          }
        }
        .p-menuitem-link {
          padding-right: 20px;

          .p-menuitem-icon {
            margin-right: 0;
          }
        }
        .p-menu-end {
          padding: 0 6px;
        }
      }

      /* Hiding scrollbar for IE, Edge and Firefox */
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE and Edge */

      &::-webkit-scrollbar {
        /* Hiding scrollbar for Chrome, Safari and Opera */
        display: none;
      }

      & > .p-menu-start {
        .mainWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: $gutter $gutter 0 $gutter;
        }
      }
      & > .p-menu-list {
        transition: all 0.2s ease-in-out;
        margin: 4rem 0 auto;
        padding-right: var(--gutter);

        .p-submenu-header {
          padding-top: 1rem;
          padding-bottom: 0;
          font-variant: all-small-caps;
          background: none;
        }
        .p-menuitem {
          margin: 8px 0;

          &:first-child {
            margin-top: 0;
          }
        }
        .p-menuitem-link {
          border: 4px solid transparent;

          &.router-link-active {
            background: var(--nav-menu-active-bg-color);
            border-style: solid;
            border-image: linear-gradient(180deg, #fffa1e 2.08%, #ffa400 47.44%, #f346f7 93.75%) 1 0
              1 100%;
            color: var(--text-color);
          }
          &.router-link-active {
            .p-menuitem-icon {
              color: var(--text-color);
            }
          }
        }
        .p-menuitem-link,
        .p-submenu-header {
          padding-left: $gutter;

          .p-menuitem-text {
            font-weight: 500;
          }
        }
      }
      & > .p-menu-end {
        position: sticky;
        display: flex;
        flex-direction: column;
        padding: 0 12px;
        bottom: 0px;
        background: var(--nav-menu-bg-color);
      }
    }
  }
}
</style>
