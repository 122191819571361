<template>
  <Navigation v-if="user?.hasGenTwoProAccess || user?.hasDocumentHubAccess" :user="user" />
  <main>
    <Message class="p-message-outlined" v-if="hashChanged" :closable="false">
      <div class="message-box p-grid">
        <div class="p-col-12 p-md-12 p-mx-2">
          <p>Gentwo Pro has been updated. Please reload to ensure you have the latest updates</p>
          <p>
            <Button @click="reloadApp">Reload</Button>
          </p>
          <p v-if="newHash != ''">
            <b>New Version:</b> {{ newHash }} (Current Version: {{ currentHash }})
          </p>
        </div>
      </div>
    </Message>
    <ImpersonatePopup v-if="user?.isImpersonating" />
    <router-view />
  </main>
  <Toast position="bottom-right" :closable="false">
    <template #icon>
      <i class="pi pi-info-circle" style="font-size: 1.7rem" />
    </template>
    <template #closeicon>
      <i class="pi pi-times-circle" style="font-size: 1.5rem" />
    </template>
  </Toast>
  <FeaturePopup :enabled="userLogged" v-if="!user?.hasDocumentHubAccess" />
  <CutoffPopup :enabled="false" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRootStore } from "@/stores";
import { useAccountStore } from "@/stores/account";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Button from "primevue/button";
import FeaturePopup from "@/components/FeaturePopup.vue";
import CutoffPopup from "@/components/CutoffPopup.vue";
import { reload } from "@/mixins/reload";
import ImpersonatePopup from "./components/elements/ImpersonatePopup.vue";
import ContactUsPopUp from "@/components/contact-us/ContactUsPopUp.vue";
import Navigation from "@/components/Navigation.vue";

export default defineComponent({
  name: "App",
  mixins: [reload],
  setup: () => {
    return {
      rootStore: useRootStore(),
      accountStore: useAccountStore(),
    };
  },
  components: {
    ContactUsPopUp,
    Toast,
    FeaturePopup,
    CutoffPopup,
    Message,
    Button,
    ImpersonatePopup,
    Navigation,
  },
  computed: {
    user() {
      return this.accountStore.user;
    },
    userLogged() {
      return this.accountStore.userLogged;
    },
  },
  async created() {
    await this.rootStore.init(this);
    this.initVersionCheck();
  },
});
</script>

<style lang="scss">
#app {
  display: flex;
  min-height: 100%;
}
main {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  padding: var(--grid-gutter) 2rem;

  & > .p-grid {
    flex: 1;
    margin: calc(-1 * var(--grid-gutter)) -2rem !important;
  }
  & > .page-header {
    padding-top: var(--grid-gutter);
  }
}
.message-box {
  color: var(--primary-color);
}
.p-toast {
  width: auto;
  max-width: calc(100% - 40px);
}
</style>
