<template>
  <div class="mobile-navigation">
    <div class="mobile-navigation-left">
      <Button
        type="button"
        icon="pi pi-bars"
        class="p-button p-component p-button-inverted p-button-md p-as-start"
        @click="toggleSidebar"
        aria-haspopup="true"
        aria-controls="overlay_menu"
      />
    </div>
    <Sidebar v-model:visible="showSidebar" blockScroll class="menu-sidebar">
      <Menu :model="items" ref="menu">
        <template #item="{ item }">
          <router-link :to="item.to" v-slot="{ navigate }" custom v-if="!item.items && item.to">
            <a
              @click="handleMenuItemClick(navigate)"
              class="p-menuitem-link"
              aria-hidden="true"
              tabindex="-1"
              :title="item.label"
            >
              <icon :data="item.icon" :fill="false" class="p-menuitem-icon" />
              <span class="p-menuitem-text">{{ item.label }}</span>
            </a>
          </router-link>
          <a
            class="p-menuitem-link"
            aria-hidden="true"
            tabindex="-1"
            v-else-if="!item.items"
            :title="item.label"
          >
            <icon :data="item.icon" :fill="false" class="p-menuitem-icon" />
            <span class="p-menuitem-text">{{ item.label }}</span>
          </a>
          <template v-else>
            <a
              class="p-menuitem-link"
              @click="toggleSublinks"
              aria-hidden="true"
              tabindex="-1"
              :title="item.label"
            >
              <icon :data="item.icon" :fill="false" class="p-menuitem-icon" />
              <span class="p-menuitem-text">{{ item.label }}</span>
              <icon
                data="@icon/arrow-down.svg"
                :fill="false"
                :dir="showSublinks ? 'down' : 'up'"
                width="1.5em"
                height="1.5em"
                class="arrow-down text-color-secondary p-ml-auto"
              />
            </a>
            <template v-if="showSublinks">
              <router-link
                v-for="subLink in item.items"
                :to="subLink.to"
                :key="subLink.name"
                v-slot="{ navigate }"
              >
                <a
                  @click="handleMenuItemClick(navigate)"
                  class="p-menuitem-link"
                  aria-hidden="true"
                  tabindex="1"
                >
                  <span class="p-menuitem-text p-ml-5">{{ subLink.label }}</span>
                </a>
              </router-link>
            </template>
          </template>
        </template>
      </Menu>
    </Sidebar>
    <div class="mobile-navigation-right">
      <ProfileMenu :user="user" collapsed :showLogout="false" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Menu from "primevue/menu";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import ProfileMenu from "./elements/ProfileMenu.vue";
import { useAccountStore } from "@/stores/account";

export default defineComponent({
  name: "MobileNavigation",
  components: { Button, ProfileMenu, Menu, Sidebar },
  props: {
    user: Object as User,
    items: Array,
  },
  data() {
    return {
      menu: null,
      showSidebar: false,
      showSublinks: false,
    };
  },
  methods: {
    toggleSublinks() {
      this.showSublinks = !this.showSublinks;
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    handleMenuItemClick(navigate) {
      this.showSidebar = false;
      navigate();
    },
  },
});
</script>
<style lang="scss">
.mobile-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--element-bg-secondary);
  padding: var(--grid-gutter) 2rem;
  color: white;
  z-index: 1000;
  .profile-menu {
    box-shadow: none;
    width: max-content;
    .p-menu-list {
      .p-menuitem-link {
        padding: 0.75rem;
      }
    }
    .p-menu {
      width: max-content;
      left: auto !important;
      right: 0;
    }
    .profile-button {
      padding: 0;
    }
    .avatar {
      font-size: 1rem;
    }
  }
  .p-button.p-button-md {
    .p-button-icon {
      font-size: 1.5rem;
    }
  }
}
.menu-sidebar {
  width: 80vw;
  background: var(--element-bg-secondary) !important;
  padding: 1.25rem;
  .p-sidebar-header {
    margin-bottom: 1.5rem;
  }
  .p-menu {
    border: none;
    background: transparent;
    .p-menuitem-link {
      padding: 1.5rem;
    }
    .p-submenu-header {
      background: transparent;
    }
  }
}
</style>
