import * as Sentry from "@sentry/vue";

export default {
  methods: {
    onAsyncComponentLoadError(error, path) {
      Sentry.addBreadcrumb({
        category: "debugging",
        message: `In ${this.name}${path ? `, can't load ${path}` : ""}. Error name is ${error?.name}`,
        level: "info",
      });
      if (this.isChunkLoadError(error)) {
        window.location.reload();
      }
    },
    isChunkLoadError(error) {
      return (
        error?.name === "ChunkLoadError" ||
        (error?.name === "TypeError" &&
          (error?.message?.includes("dynamically imported module") ||
            error?.message?.includes("module script failed")))
      );
    },
    isPreloadCSSError(error) {
      return error?.name === "Error" && error?.message?.includes("Unable to preload CSS");
    },
  },
};
