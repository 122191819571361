<template>
  <Dialog
    v-model:visible="visible"
    :closeOnEscape="true"
    :closable="true"
    :contentStyle="{ overflow: 'visible' }"
    :modal="true"
    @hide="onHide"
    class="feature-popup"
    v-if="enabled"
  >
    <template #header>
      <h2 class="p-dialog-title">Product Change Request and Enhanced Workflow Functionalities</h2>
      <Divider class="p-dialog-divider p-mb-0 p-mt-5" />
    </template>

    <div>
      <p>
        Requesting product changes just got much easier. With the new area in GenTwo Pro you can now
        easily select what type of product change you want without losing sight of any associated
        costs.
      </p>
      <p>
        We have introduced a new Workflow Menu Bar and Status Tabs to give you a clearer overview of
        your tasks and help you easily access pending workflows that need your immediate attention.
      </p>
      <p>
        <a
          class="element-link p-p-0"
          target="_blank"
          href="https://www.gentwo.com/communications/product-change-request-and-enhanced-workflow-functionalities"
        >
          Click here to read more about these new functionalities.</a
        >
      </p>
    </div>
    <template #footer>
      <div class="p-d-flex p-jc-between">
        <div class="srf-form p-d-flex p-input-filled">
          <Checkbox :binary="true" id="hideNextTime" name="hideNextTime" v-model="hideNextTime">
            Don’t show next time
          </Checkbox>
        </div>
        <Button label="Close" @click="visible = false" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Checkbox from "@/components/elements/Checkbox";
import { useCookies } from "vue3-cookies";
import Divider from "primevue/divider";

export default {
  name: "FeaturePopup",
  components: { Dialog, Button, Checkbox, Divider },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    enabled: Boolean,
  },
  data() {
    return {
      visible: false,
      hideNextTime: false,
      currentPopupDate: "13.05.2024", // Date should be changed manually in order to show popup to users
      lastSeenPopupDate: this.cookies.get("featureCookie_date"),
    };
  },
  created() {
    if (this.lastSeenPopupDate !== this.currentPopupDate) {
      this.visible = true;
    }
  },
  methods: {
    onHide() {
      const hideNextTime = this.hideNextTime;
      if (hideNextTime) {
        this.cookies.set("featureCookie_date", this.currentPopupDate, "365d");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.p-dialog-title {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  line-height: 2rem;
}
.feature-popup p {
  font-size: 1.34rem;
}
.header-bg {
  width: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}
</style>
