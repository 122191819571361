<template>
  <MobileNavigation v-if="isSmallScreen" :user="user" :items="menuItems" />
  <Header v-else :user="user" :items="menuItems" />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useAccountStore } from "@/stores/account";
import Header from "@/components/Header.vue";
import MobileNavigation from "@/components/MobileNavigation.vue";
import { useGrid } from "vue-screen";

export default defineComponent({
  name: "Navigation",
  components: { Header, MobileNavigation },
  setup: () => {
    return { accountStore: useAccountStore(), grid: useGrid() };
  },
  data() {
    return {
      loggingOut: false,
    };
  },
  props: {
    user: Object as User,
  },
  computed: {
    isProduction() {
      return import.meta.env.VITE_APP_ENVIRONMENT === "production";
    },
    hasSrfAccess() {
      return this.user?.srfAccess && !this.user?.isUserPayingAgent && !this.user?.isUserIssuer;
    },
    hasSrfLiteAccess() {
      return this.user?.srfLiteAccess || this.user?.isUserGentwo;
    },
    menuItems() {
      return [
        ...(!this.user.hasDocumentHubAccess && (this.user.isUserGentwo || this.user.isClient)
          ? [
              {
                label: "Dashboard",
                icon: require("@icon/dashboard.svg"),
                to: { name: "Dashboard" },
              },
            ]
          : []),
        ...(this.user.hasGenTwoProAccess
          ? [
              {
                label: "Create",
                items: [
                  {
                    label: "New Product",
                    icon: require("@icon/new-product.svg"),
                    to: { name: "SRF", params: { id: "new" } },
                    visible: () => this.hasSrfAccess,
                  },
                  {
                    label: "New AMC for Banks",
                    icon: require("@icon/new-product.svg"),
                    to: { name: "SRFLite", params: { id: "new" } },
                    isNewFeature: true,
                    visible: () => this.hasSrfLiteAccess && !this.isProduction,
                  },
                  {
                    label: "New Workflow",
                    icon: require("@icon/new-workflow.svg"),
                    to: { name: "NewWorkflow" },
                  },
                  {
                    label: "New Order Acceptance Template",
                    icon: require("@icon/new-order-acceptance-template.svg"),
                    to: { name: "OrderAcceptanceTemplate", params: { action: "new" } },
                    visible: () =>
                      (!this.user?.isUserIssuer || this.user?.isUserGentwo) &&
                      this.user?.isOrderAcceptancePayingAgent,
                  },
                ],
              },
            ]
          : []),
        {
          label: "Business",
          items: [
            ...(this.user.hasGenTwoProAccess
              ? [
                  {
                    label: "Products",
                    icon: require("@icon/products.svg"),
                    to: { name: "Products" },
                  },
                  {
                    label: "Product Issuance",
                    icon: require("@icon/product-issuance.svg"),
                    to: { name: "SRFRequests" },
                    visible: () => this.hasSrfAccess,
                  },
                  {
                    label: "Workflows",
                    icon: require("@icon/workflows.svg"),
                    to: { name: "Workflows", params: { type: "all", status: "pending" } },
                  },
                  {
                    label: "Order Acceptance Templates",
                    icon: require("@icon/order-acceptance-templates.svg"),
                    to: { name: "OrderAcceptanceTemplates" },
                    visible: () => !this.user?.isUserIssuer || this.user?.isUserGentwo,
                  },
                  {
                    label: "Planned Coupons",
                    icon: require("@icon/coupons.svg"),
                    to: { name: "PlannedCoupons" },
                    visible: () => this.user?.isUserPayingAgent || this.user?.isUserGentwo,
                  },
                ]
              : []),

            {
              label: "Documents",
              icon: require("@icon/documents.svg"),
              to: { name: "DocumentHub" },
              ...(this.isSmallScreen && {
                items: [
                  {
                    label: "Gentwo Documents",
                    icon: require("@icon/support.svg"),
                    to: { name: "NonDigitalDocuments" },
                  },
                  {
                    label: "Gentwo Digital Documents",
                    icon: require("@icon/support.svg"),
                    to: { name: "DigitalDocuments" },
                  },
                ],
              }),
            },
          ],
        },
        ...(this.user.hasGenTwoProAccess
          ? [
              {
                label: "Support",
                items: [
                  { label: "Support", icon: require("@icon/support.svg"), to: { name: "Support" } },
                ],
              },
            ]
          : []),
        ...(this.isSmallScreen
          ? [
              {
                label: "Log Out",
                icon: require("@icon/logout.svg"),
                command: async () => {
                  this.loggingOut = true;

                  await this.accountStore.logout();
                  this.$router.push({ name: "Login" });

                  this.loggingOut = false;
                },
              },
            ]
          : []),
      ];
    },
    isSmallScreen() {
      return !this.grid["md"];
    },
  },
});
</script>
